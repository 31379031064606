import React, { useCallback, useContext, useEffect, useState } from "react";
import styles from "./validate.module.scss";

export default function Validate() {
  return (
    <div className={styles.auth}>
      {/* <GoogleReCaptcha refreshReCaptcha={false} onVerify={onVerify} /> */}
      <div className={styles.authGrid}>
        <div className={styles.formColumn}>
          <img src="/logo.svg" alt="logo" />
          <div className={styles.formContainer}>
            <h1>Verify your email address</h1>
            <h2>Last step, to start getting new leads</h2>
            <br />
            <b>Check your Spam folder and make sure you mark it as Not Spam</b>
          </div>
        </div>
        <div className={styles.imageColumn}>
          {/* <img src="/auth-01.jpeg" alt="" /> */}
        </div>
      </div>
    </div>
  );
}
