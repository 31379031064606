import { Link } from "@reach/router";
import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./tools.module.scss";
import toast from "react-hot-toast";
import { Context } from "../../Context";
import LeadModal from "../modals/Lead";
import SingleLeadModal from "../modals/SingleLead";
import ContactImportModal from "../modals/contact-import/contact-import.modal";
import SalesNavigatorModal from "../modals/sales-navigator/sales-navigator.modal";
import ReactTooltip from "react-tooltip";
import cx from "classnames";
import axios from "axios";
import EnrichmentModal from "../modals/enrichment/enrichment.modal";
import BuyValidationCreditModal from "../modals/buy-validation-credits/buy-validation-credits.modal";
const api = process.env.REACT_APP_API;

export default function ToolsComponent({
  campaign,
  broadcast,
  limit,
  total,
  user,
  refresh,
  capture,
  salesNavigator,
  noPaddings,
  enableSalesNavigator = true,
  enableEnrichment = true,
  enableAISearch = true,
  enableOpenEmail = false,
  openEmailBuilder,
}) {
  const leadModalRef = useRef();
  const userModalRef = useRef();
  const linkedInModalRef = useRef();
  const contactImportModalRef = useRef();
  const buyEnrichmentModal = useRef();
  const buyValidationCreditsModal = useRef();
  const [openImport, setOpenImport] = useState(false);
  const [openEnrichment, setOpenEnrichment] = useState(false);
  const [openValidationBuy, setValidationOpenBuy] = useState(false);
  const value = useContext(Context);
  const updateUser = () => {
    axios.get(`${api}user/get/id/${user._id}`).then(({ data }) => {
      value.updateUser(data);
    });
  };
  const validateLimits = () => {
    if (!limit || total >= limit) {
      toast.error(
        "you reached the limit of contacts in your account, please contact support to increase your limits."
      );
      return true;
    }
  };

  const openSingle = () => {
    if (validateLimits()) {
      return;
    }
    leadModalRef.current.open(user, campaign, broadcast);
  };

  const openImportModal = () => {
    setOpenImport(true);
  };

  const openEnrichmentModal = () => {
    setOpenEnrichment(true);
  };

  const openUploadList = () => {
    if (validateLimits()) {
      return;
    }
    userModalRef.current.open(user, campaign, total, broadcast);
  };

  const openUploadLinkedin = (search, cookie) => {
    linkedInModalRef.current.open(search, cookie);
  };

  useEffect(() => {
    if (salesNavigator && salesNavigator.open) {
      openUploadLinkedin(salesNavigator.search, salesNavigator.cookie);
    }
  }, [salesNavigator]);

  useEffect(() => {
    if (contactImportModalRef.current) {
      contactImportModalRef.current.open(campaign, broadcast);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openImport]);

  return (
    <div className={cx(noPaddings && styles.noPaddings, "container")}>
      <div className={styles.tools}>
        {campaign && (
          <>
            {campaign.version > 3.5 ? (
              <Link to={`/campaign/flow/${campaign._id}`}>
                <div className={styles.item}>Email Sequence</div>
              </Link>
            ) : (
              <Link to={`/campaign/old/${campaign._id}`}>
                <div className={styles.item}>Email Sequence</div>
              </Link>
            )}
          </>
        )}
        {enableOpenEmail && (
          <button className={styles.item} onClick={() => openEmailBuilder()}>
            Create an Email
          </button>
        )}
        <button className={styles.item} onClick={() => openSingle()}>
          Add Single Contact
        </button>
        <button className={styles.item} onClick={() => openUploadList()}>
          Upload My Own List
        </button>
        {(campaign || broadcast) && (
          <button className={styles.item} onClick={() => openImportModal()}>
            Import Contacts
          </button>
        )}
        {enableAISearch && (
          <>
            {capture ? (
              <Link to={`/searches/add/${campaign._id}`}>
                <div
                  className={styles.item}
                  data-tip
                  data-for={`Tooltip-get-leads`}
                >
                  <img
                    src="https://automatedinbox.s3.ap-southeast-1.amazonaws.com/ai-icon-e1672106929333.png"
                    alt="icon"
                  />
                  Get Leads
                </div>
              </Link>
            ) : (
              <Link to={`/searches/add/`}>
                <div
                  className={styles.item}
                  data-tip
                  data-for={`Tooltip-get-leads`}
                >
                  <img
                    src="https://automatedinbox.s3.ap-southeast-1.amazonaws.com/ai-icon-e1672106929333.png"
                    alt="icon"
                  />
                  Get Leads
                </div>
              </Link>
            )}
          </>
        )}

        <ReactTooltip id={`Tooltip-get-leads`} place="top" type="dark">
          <div className="tooltip-box">
            Get Leads using Automated Inbox Lead Search
          </div>
        </ReactTooltip>
        {enableSalesNavigator && (
          <a
            className={styles.item}
            href="https://www.linkedin.com/sales/search/people"
            target="_blank"
            data-tip
            data-for={`Tooltip-sales-nav`}
          >
            <i
              style={{ color: "#004e89", fontSize: 14 }}
              className="fab fa-linkedin-in"
              data-tip
              data-for={`Tooltip-linkedin`}
            ></i>{" "}
            Sales Navigator
          </a>
        )}
        {enableEnrichment && (
          <div
            className={styles.item}
            onClick={() => openEnrichmentModal()}
            data-tip
            data-for={`Tooltip-sales-nav`}
          >
            <i
              style={{ color: "#004e89", fontSize: 14 }}
              className="fal fa-poll-people"
              data-tip
              data-for={`Tooltip-linkedin`}
            ></i>{" "}
            Enrichment
          </div>
        )}
      </div>
      <ReactTooltip id={`Tooltip-sales-nav`} place="top" type="dark">
        <div className="tooltip-box">
          Get Leads using Sales Navigator. Must install Chrome extension
        </div>
      </ReactTooltip>

      <LeadModal
        ref={userModalRef}
        loadData={refresh}
        openBuyValidation = {() => {setValidationOpenBuy(true)}}
        showLoading={() => {}}
        closeLoading={() => {}}
        openHowItWorks={() => {}}
      />
      <SingleLeadModal
        ref={leadModalRef}
        loadData={refresh}
        openHowItWorks={() => {}}
      />
      <SalesNavigatorModal
        ref={linkedInModalRef}
        loadData={refresh}
        openHowItWorks={() => {}}
      />
      {openEnrichment && (
        <EnrichmentModal ref={buyEnrichmentModal} setOpen={setOpenEnrichment} />
      )}
      {openImport && (
        <ContactImportModal
          ref={contactImportModalRef}
          refresh={refresh}
          setOpen={setOpenImport}
        />
      )}
      {openValidationBuy && (
        <BuyValidationCreditModal
          ref={buyValidationCreditsModal}
          setOpen={setValidationOpenBuy}
          updateUser={updateUser}
        />
      )}
    </div>
  );
}
