import React, { useEffect, useState, useRef, useContext } from "react";
import { useLocation } from "@reach/router";
import axios from "axios";
import { Context } from "../../Context";
import Loading from "react-loading-bar";
import CampaignLayout from "../../layouts/campaign.layout";
import ToolsComponent from "../../components/tools/tools.component";
import LeadsTableComponent from "../../components/leads-table/leads-table.component";
import LeadsHeaderComponent from "../../components/leads-header/leads-header.component";
import FilterLeadsComponent from "../../components/filter-leads/filter-leads.component";
import { parse } from "query-string";
import toast from "react-hot-toast";
import ExportModal from "../../components/modals/export/export.modal";

export default function ContactsPage() {
  const value = useContext(Context);
  const api = process.env.REACT_APP_API;
  const [name, setName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [campaignStats, setCampaignStats] = useState(undefined);
  const [criteria, setCriteria] = useState(undefined);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [leads, setLeads] = useState([]);
  const [salesNavigator, setSalesNavigator] = useState(undefined);
  const location = useLocation();
  const [mailStatus, setMailStatus] = useState([]);
  const [phone, setPhone] = useState(false);
  const [openExportModal, setOpenExportModal] = useState(false);
  const openExportModalRef = useRef();
  const [campaign, setCampaign] = useState(undefined);

  const handlePerRowsChange = async (newPerPage, page) => {
    setRowsPerPage(newPerPage);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const setFilter = (param) => {
    if (criteria === param) {
      setCriteria(undefined);
    } else {
      setCriteria(param);
    }
  };

  useEffect(() => {
    filtrar();
  }, [name, page, rowsPerPage, criteria, mailStatus, phone]);

  useEffect(() => {
    const searchParams = parse(location.search);
    if (searchParams.ai === "1") {
      setSalesNavigator({
        open: true,
        cookie: searchParams.li_at,
        search: searchParams.search,
      });
    }
  }, []);

  const updateFilters = (name, mailStatus, phone) => {
    setName(name);
    setMailStatus(mailStatus);
    setPhone(phone);
  };

  const download = (campaign, broadcast) => {
    axios
      .get(`${api}lead/download`, {
        params: {
          criteria: criteria,
          user: value.user._id,
          campaign: campaign ? campaign : undefined,
          broadcast: broadcast ? broadcast : undefined,
          filterByName: name,
          rowsPerPage: rowsPerPage,
          mailStatus: mailStatus,
          phone: phone,
        },
      })
      .then(({ data }) => {
        const temp = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = temp;
        link.setAttribute("download", "leads.csv"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
  };

  const refreshUsers = () => {
    axios
      .get(`${api}lead/all`, {
        params: {
          user: value.user._id,
          page: page,
          filterByName: name,
          rowsPerPage: rowsPerPage,
          criteria: criteria,
          mailStatus: mailStatus,
          phone: phone,
        },
      })
      .then(({ data }) => {
        if (data.error) {
          console.log(data.error);
        } else {
          setLeads(data.docs);
        }
      });
  };

  const filtrar = () => {
    const promise = axios
      .get(`${api}lead/all`, {
        params: {
          user: value.user._id,
          page: page,
          filterByName: name,
          rowsPerPage: rowsPerPage,
          criteria: criteria,
          mailStatus: mailStatus,
          phone: phone,
        },
      })
      .then(({ data }) => {
        if (data.error) {
          console.log(data.error);
        } else {
          // if (setTotalLeads) {
          //   setTotalLeads(data.totalDocs);
          // }
          setTotal(data.totalDocs);
          setLeads(data.docs);
        }
      });

    toast.promise(promise, {
      loading: "Getting data...",
      success: "Got data successfully!",
      error: "Uh oh, there was an error!",
    });

    axios
      .get(`${api}lead/campaign-leads-stats`, {
        params: {
          user: value.user._id,
        },
      })
      .then(({ data }) => {
        setCampaignStats(data[0]);
        if (Object.keys(data[0]).length !== 0) {
          // setStats(data[0]);
        }
      });
  };

  const openExport = (campaign) => {
    setOpenExportModal(true);
    setCampaign(campaign);
  };

  useEffect(() => {
    if (openExportModal) {
      let body = {
        user: value.user._id,
        campaign: campaign,
        filterByName: name,
        criteria: criteria,
        mailStatus: mailStatus,
      };
      openExportModalRef.current.open(body);
    }
  }, [openExportModal, campaign]);

  return (
    <CampaignLayout user={value.user}>
      <ToolsComponent
        user={value.user}
        limit={value.user?.limits?.contacts}
        total={total}
        refresh={filtrar}
        salesNavigator={salesNavigator}
      />
      <div className="stats">
        <div className="container">
          <div className="users-heading">
            <h3>
              <LeadsHeaderComponent
                setFilter={setFilter}
                campaignStats={campaignStats}
                criteria={criteria}
                user={value.user}
                download={download}
                openExport={openExport}
                refresh={filtrar}
              />
            </h3>
            <FilterLeadsComponent filter={updateFilters} />
          </div>
        </div>
        {leads ? (
          <section className="container">
            <LeadsTableComponent
              rowsPerPageLeads={rowsPerPage}
              page={page}
              total={total}
              handlePerRowsChange={handlePerRowsChange}
              handlePageChange={handlePageChange}
              leads={leads}
              refresh={refreshUsers}
            />
          </section>
        ) : (
          <Loading />
        )}
      </div>
      {openExportModal && (
        <ExportModal
          ref={openExportModalRef}
          refresh={refreshUsers}
          setOpen={setOpenExportModal}
        />
      )}
    </CampaignLayout>
  );
}
