import React, { useContext, useMemo, useState, useEffect } from "react";
import { Context } from "../../Context";
import { useInputValue } from "../../hooks/useInputValue";
import CampaignLayout from "../../layouts/campaign.layout";
import styles from "./add-search.module.scss";
import axios from "axios";
import cx from "classnames";
import NumberFormat from "react-number-format";
import ReactTooltip from "react-tooltip";
import toast from "react-hot-toast";
import { useNavigate } from "@reach/router";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import SearchResultsComponent from "../../components/search-results/search-results.component";

export default function AddSearchPage({ campaign }) {
  const value = useContext(Context);
  const [step, setStep] = useState(1);
  const [limit, setLimit] = useState(0);
  const [preview, setPreview] = useState([]);
  const [previousSearch, setPreviousSearch] = useState(undefined);
  const [start_status, setStatus] = useState(false);
  const [campaignAI, setSearchesCampaign] = useState(undefined);
  const [selectedCampaign, setSelectedCampaign] = useState(campaign);
  const [scrollToken, setScrollToken] = useState(undefined);

  const qtyLeads = useInputValue(0);
  const type = useInputValue(0);
  const name = useInputValue("");

  const country = useInputValue([]);
  const state = useInputValue([]);
  const city = useInputValue("");
  const jobTitle = useInputValue([]);
  const industry = useInputValue([]);
  const seniority = useInputValue([]);
  const companySize = useInputValue([]);

  const textJobTitle = useInputValue("");
  const fullTextJobTitle = useInputValue(false);
  const personalEmail = useInputValue(true);
  const includePhoneNumber = useInputValue(false);

  const handleChange = (event) => {
    setSelectedCampaign(event.target.value);
  }

  // autocomplete
  const [count, setCount] = useState(0);
  const api = process.env.REACT_APP_API;
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${api}lead/campaigns`, {
        params: {
          user: value.user._id,
        },
      })
      .then(({ data }) => {
        if (data.error) {
          console.log(data.error);
        } else {
          setSearchesCampaign(data.filter((list) => list.name !== undefined));
        }
      });
  }, []);

  const customStyles = {
    control: () => ({
      border: "1px solid #e0e1e4",
      display: "flex",
      borderRadius: 5,
      background: "#FFF",
    }),
    placeholder: () => ({
      fontFamily:
        "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif;",
      fontWeight: 500,
      letterSpacing: 0.3,
      color: "#c3c3c3",
      fontSize: 13,
      gridArea: "1 / 1 / 2 / 3",
      position: "absolute",
    }),
    multiValueLabel: () => ({
      fontFamily:
        "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif;",
      fontWeight: 500,
      letterSpacing: 0.3,
      fontSize: 12,
      padding: 5,
    }),
    singleValue: () => ({
      fontFamily:
        "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif;",
      fontWeight: 500,
      letterSpacing: 0.3,
    }),
  };

  const companySizeOptions = [
    {
      value: "1-10",
      label: "1-10",
    },
    {
      value: "11-50",
      label: "11-50",
    },
    {
      value: "51-200",
      label: "51-200",
    },
    {
      value: "201-500",
      label: "201-500",
    },
    {
      value: "501-1000",
      label: "501-1000",
    },
    {
      value: "1001-5000",
      label: "1001-5000",
    },
    {
      value: "5001-10000",
      label: "5001-10000",
    },
    {
      value: "10001+",
      label: "10001+",
    },
  ]

  const jobTitleLeves = [
    {
      value: "cxo",
      label: "cxp",
    },
    {
      value: "director",
      label: "director",
    },
    {
      value: "entry",
      label: "entry",
    },
    {
      value: "manager",
      label: "manager",
    },
    {
      value: "owner",
      label: "owner",
    },
    {
      value: "partner",
      label: "partner",
    },
    {
      value: "senior",
      label: "senior",
    },
    {
      value: "training",
      label: "training",
    },
    {
      value: "unpaid",
      label: "unpaid",
    },
    {
      value: "vp",
      label: "vp",
    },
  ];

  const searchCount = async () => {
    if (
      !value.user.limits ||
      !value.user.limits.searchs ||
      value.user.limits.searchs <= 0
    ) {
      toast.error(
        "You don't have more searches remaining, if you want more search buy some credits or contact our support."
      );
      return;
    }

    let search = [
      {
        exists: {
          field: "emails",
        },
      },
    ];

    if (includePhoneNumber.value) {
      search.push({
        exists: {
          field: "phone_numbers",
        },
      });
    }

    if (!personalEmail.value) {
      search.push({
        exists: {
          field: "work_email",
        },
      });
    }

    if (country.value && country.value.length > 0) {
      let countries = country.value.map((j) => {
        return j.value;
      });
      search.push({
        bool: {
          should: [
            {
              terms: {
                location_country: countries,
              },
            },
          ],
        },
      });
    }

    if (state.value && state.value.length > 0) {
      let states = state.value.map((j) => {
        return j.value;
      });
      search.push({
        bool: {
          should: [
            {
              terms: {
                location_region: states,
              },
            },
          ],
        },
      });
    }

    if (city.value) {
      search.push({
        term: {
          location_locality: city.value.meta.locality,
        },
      });
    }

    if (fullTextJobTitle.value) {
      if (textJobTitle.value !== "") {
        search.push({
          bool: {
            should: [
              {
                match: {
                  "job_title.text": textJobTitle.value,
                },
              },
            ],
          },
        });
      }
    } else {
      if (jobTitle.value && jobTitle.value.length > 0) {
        let title = jobTitle.value.map((j) => {
          return j.value;
        });
        search.push({
          bool: {
            should: [
              {
                terms: {
                  job_title: title,
                },
              },
            ],
          },
        });
      }
    }

    if (seniority.value && seniority.value.length > 0) {
      let title = seniority.value.map((j) => {
        return j.value;
      });
      search.push({
        bool: {
          should: [
            {
              terms: {
                job_title_levels: title,
              },
            },
          ],
        },
      });
    }

    if (companySize.value && companySize.value.length > 0) {
      let title = companySize.value.map((j) => {
        return j.value;
      });
      search.push({
        bool: {
          should: [
            {
              terms: {
                job_company_size: title,
              },
            },
          ],
        },
      });
    }

    if (industry.value && industry.value.length > 0) {
      let industries = industry.value.map((j) => {
        return j.value;
      });
      search.push({
        bool: {
          should: [
            {
              terms: {
                industry: industries,
              },
            },
          ],
        },
      });
    }

    let validateBody = {
      user: value.user._id,
      country: cleanArray(country.value),
      title: cleanArray(jobTitle.value),
      industry: cleanArray(industry.value),
      city: city.value?.meta?.locality,
      state: cleanArray(state.value),
      seniority: cleanArray(seniority.value),
      companySize: cleanArray(companySize.value),
      textJobTitle: textJobTitle.value.trim(),
    };

    if (
      !validateBody.country &&
      !validateBody.title &&
      !validateBody.industry &&
      !validateBody.city &&
      !validateBody.state &&
      !validateBody.seniority &&
      !validateBody.companySize &&
      !validateBody.textJobTitle
    ) {
      toast.error("You need to set one filter at least.");
      return;
    }

    let counts = await axios.post(`${api}search/validate-filter`, validateBody);
    let query = {
      query: {
        bool: {
          must: search,
        },
      },
      size: 10,
      from: counts.data.sum,
    };
    setCount(counts.data.sum);
    
    const promise = axios
      .post(
        `https://mj29feydm8.execute-api.us-east-1.amazonaws.com/default/leadcount`,
        query
      )
      .then(({ data }) => {
        setPreviousSearch(data);
        setScrollToken(data.scroll_token)
        setLimit(data.total);
        setPreview(data.data);
        // setStep(2);
        axios
          .post(`${api}user/decrease-searchs`, {
            _id: value.user._id,
          })
          .then(({ data }) => {
            value.updateUser(data);
          })
          .finally(() => {
            setStep(2);
          });
      });

    toast.promise(promise, {
      loading: "Searching...",
      success: "Search completed!",
      error: "your search returned 0 results, try a different search",
    });
  };

  const cleanArray = (ar) => {
    let clean = "";
    ar.forEach((a, i) => {
      if (i === 0) {
        clean += a.value;
      } else {
        clean += `,${a.value}`;
      }
    });
    return clean;
  };

  const handleOnSearchCountry = (inputValue, callback) => {
    let body = {
      field: "country",
      text: inputValue.toLowerCase(),
    };

    axios
      .get(
        `https://automatedinbox-autocomplete.vercel.app/v1/autocomplete/search`,
        {
          params: body,
        }
      )
      .then(({ data }) => {
        callback(
          data.data.map((x) => {
            return {
              value: x.name,
              label: `${x.name}`,
            };
          })
        );
      });
  };

  const handleOnSearchCity = (inputValue, callback) => {
    let body = {
      field: "location",
      text: inputValue.toLowerCase(),
    };

    axios
      .get(
        `https://automatedinbox-autocomplete.vercel.app/v1/autocomplete/search`,
        {
          params: body,
        }
      )
      .then(({ data }) => {
        // setCities(
        //   data.data.map((x) => {
        //     return {
        //       id: x.name,
        //       name: x.name,
        //       meta: x.meta,
        //     };
        //   })
        // );
        callback(
          data.data.map((x) => {
            return {
              value: x.name,
              label: x.name,
              meta: x.meta,
            };
          })
        );
      });
  };

  const handleOnSelectCountry = (item) => {
    country.setValue(item);
  };

  const handleOnSelectCity = (item) => {
    if (item) {
      country.setValue([
        {
          value: item.meta.country,
          label: item.meta.country,
        },
      ]);
      state.setValue([
        {
          value: item.meta.region,
          label: item.meta.region,
        },
      ]);
      city.setValue(item);
    } else {
      city.setValue(undefined);
    }
  };

  const handleOnSearchIndustry = (inputValue, callback) => {
    let body = {
      field: "industry",
      text: inputValue.toLowerCase(),
    };

    axios
      .get(
        `https://automatedinbox-autocomplete.vercel.app/v1/autocomplete/search`,
        {
          params: body,
        }
      )
      .then(({ data }) => {
        callback(
          data.data.map((x) => {
            return {
              value: x.name,
              label: `${x.name}`,
            };
          })
        );
      });
  };

  const handleOnSelectIndustry = (item) => {
    industry.setValue(item);
  };

  const handleOnSearchTitle = (inputValue, callback) => {
    let body = {
      field: "title",
      text: inputValue.toLowerCase(),
    };

    axios
      .get(
        `https://automatedinbox-autocomplete.vercel.app/v1/autocomplete/search`,
        {
          params: body,
        }
      )
      .then(({ data }) => {
        callback(
          data.data.map((x) => {
            return {
              value: x.name,
              label: `${x.name}`,
            };
          })
        );
      });
  };

  const handleOnSelectTitle = (item) => {
    jobTitle.setValue(item);
  };

  const handleOnSelectSeniority = (item) => {
    seniority.setValue(item);
  };

  const handleOnSelectCompanySize = (item) => {
    companySize.setValue(item)
  }

  const handleOnSearchState = (inputValue, callback) => {
    let body = {
      field: "region",
      text: inputValue.toLowerCase(),
    };

    axios
      .get(
        `https://automatedinbox-autocomplete.vercel.app/v1/autocomplete/search`,
        {
          params: body,
        }
      )
      .then(({ data }) => {
        callback(
          data.data.map((x) => {
            return {
              value: x.name,
              label: `${x.name}`,
            };
          })
        );
      });
  };

  const handleOnSelectState = (item) => {
    state.setValue(item);
  };

  const setFilters = (
    _country,
    _state,
    _city,
    _title,
    _seniority,
    _industry,
    _textJobTitle,
    _personalEmail,
    _companySize
  ) => {
    if (_country) {
      let arr = _country.split(",");
      arr = arr.map((a) => {
        return {
          label: a,
          value: a,
        };
      });
      country.setValue(arr);
    } else {
      country.setValue([]);
    }

    if (_state) {
      let arr = _state.split(",");
      arr = arr.map((a) => {
        return {
          label: a,
          value: a,
        };
      });
      state.setValue(arr);
    } else {
      state.setValue([]);
    }

    if (_title) {
      let arr = _title.split(",");
      arr = arr.map((a) => {
        return {
          label: a,
          value: a,
        };
      });
      jobTitle.setValue(arr);
    } else {
      jobTitle.setValue([]);
    }

    if (_city) {
      city.setValue(_city);
    } else {
      city.setValue("");
    }

    if (_seniority) {
      let arr = _seniority.split(",");
      arr = arr.map((a) => {
        return {
          label: a,
          value: a,
        };
      });
      seniority.setValue(arr);
    } else {
      seniority.setValue([]);
    }

    if (_companySize) {
      let arr = _companySize.split(",");
      arr = arr.map((a) => {
        return {
          label: a,
          value: a,
        };
      });
      companySize.setValue(arr);
    } else {
      companySize.setValue([]);
    }

    if (_industry) {
      let arr = _industry.split(",");
      arr = arr.map((a) => {
        return {
          label: a,
          value: a,
        };
      });
      industry.setValue(arr);
    } else {
      industry.setValue([]);
    }

    if (_textJobTitle) {
      fullTextJobTitle.setValue(true);
      textJobTitle.setValue(_textJobTitle);
    } else {
      textJobTitle.setValue("");
    }

    if (typeof _personalEmail !== "undefined") {
      if (_personalEmail) {
        personalEmail.setValue(true);
      } else {
        personalEmail.setValue(false);
      }
    } else {
      personalEmail.setValue(true);
    }
  };

  const startSearch = async () => {
    if (start_status) {
      return;
    }
    setStatus(true);
    if (qtyLeads.value > value.user.credit) {
      toast.error(
        `You exceeded the limit of leads, your limit is ${value.user.credit}`
      );
      return;
    }
    let query = previousSearch;
    query.size = qtyLeads.value;
    query.is_premium = type.value * 1 === 2 ? true : false;
    query.user = value.user._id;
    if (selectedCampaign) {
      query.campaign = selectedCampaign;
    }

    let validateBody = {
      user: value.user._id,
      country: cleanArray(country.value),
      title: cleanArray(jobTitle.value),
      industry: cleanArray(industry.value),
      city: city.value?.meta?.locality,
      state: cleanArray(state.value),
      seniority: cleanArray(seniority.value),
      companySize: cleanArray(companySize.value),
      textJobTitle: textJobTitle.value.trim(),
      campaign: campaign,
      credits: qtyLeads.value,
      rate: qtyLeads.value,
      premium: type.value * 1 === 2 ? true : false,
      personalEmail: personalEmail.value,
      name: name.value,
      scroll_token: scrollToken
    };

    let search = await axios.post(`${api}search/add`, validateBody);
    query.search = search.data._id;

    axios
      .post(
        `https://mj29feydm8.execute-api.us-east-1.amazonaws.com/default/personsearch-v2`,
        query,
        {
          headers: {
            InvocationType: "Event",
          },
        }
      )
      .then(() => {
        toast.success(
          "Your search has started, this can take some minutes to process, you will see your new leads appear in your campaign."
        );

        axios
          .post(`${api}user/decrease-credits`, {
            _id: value.user._id,
            creditsUsed: qtyLeads.value * type.value,
          })
          .then(({ data }) => {
            value.updateUser(data);
            navigate(`/automated-inbox-searches/${search.data._id}`);
          });
      });
  };

  const validateStart = useMemo(() => {
    let invalid = true;
    if (
      type.value !== 0 &&
      qtyLeads.value > 0 &&
      qtyLeads.value <= value.user.credit &&
      !start_status &&
      name.value !== ""
    ) {
      invalid = false;
    }

    return invalid;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, qtyLeads]);

  return (
    <CampaignLayout user={value.user}>
      <div className={styles.addSearch}>
        <div className="container">
          <div className={styles.searchContainer}>
            <div>
              <div className={styles.filterContainer}>
                <div className={styles.header}>
                  Filters
                  <h3>{value.user.limits.searchs} Searches remaining</h3>
                </div>
                <div className={styles.filters}>
                  <div className={styles.filterBox}>
                    <div
                      className={cx(
                        styles.fieldGroup,
                        styles.autocomplete,
                        styles.z100000
                      )}
                    >
                      <label>Country</label>
                      <AsyncSelect
                        isMulti
                        styles={customStyles}
                        value={country.value}
                        cacheOptions
                        loadOptions={handleOnSearchCountry}
                        defaultOptions
                        onChange={handleOnSelectCountry}
                        placeholder={"Ex. United States"}
                        // onInputChange={handleOnSelectCity}
                      />
                    </div>
                  </div>
                  <div className={styles.filterBox}>
                    <div
                      className={cx(
                        styles.fieldGroup,
                        styles.autocomplete,
                        styles.z100000
                      )}
                    >
                      <label>State</label>
                      <AsyncSelect
                        isMulti
                        styles={customStyles}
                        value={state.value}
                        cacheOptions
                        loadOptions={handleOnSearchState}
                        defaultOptions
                        onChange={handleOnSelectState}
                        placeholder={"Ex. New York"}
                        // onInputChange={handleOnSelectCity}
                      />
                    </div>
                  </div>
                  <div className={styles.filterBox}>
                    <div className={styles.fieldGroup}>
                      <label>City</label>
                      <AsyncSelect
                        styles={customStyles}
                        cacheOptions
                        isClearable
                        loadOptions={handleOnSearchCity}
                        defaultOptions
                        onChange={handleOnSelectCity}
                        // onInputChange={handleOnSelectCity}
                      />
                    </div>
                  </div>
                  <div className={styles.filterBox}>
                    <div className={cx(styles.fieldGroup, styles.autocomplete)}>
                      <label>Job Title</label>
                      {fullTextJobTitle.value ? (
                        <input
                          type="text"
                          placeholder="Enter Job Title"
                          className={styles.input}
                          value={textJobTitle.value}
                          onChange={textJobTitle.onChange}
                        />
                      ) : (
                        <AsyncSelect
                          isMulti
                          styles={customStyles}
                          value={jobTitle.value}
                          cacheOptions
                          loadOptions={handleOnSearchTitle}
                          defaultOptions
                          onChange={handleOnSelectTitle}
                          placeholder={"Ex. chief executive officer"}
                        />
                      )}

                      <div className={styles.checkbox}>
                        <label>
                          <input
                            type="checkbox"
                            checked={fullTextJobTitle.value}
                            onChange={(e) =>
                              fullTextJobTitle.setValue(e.target.checked)
                            }
                          />{" "}
                          Full Text Search
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className={styles.filterBox}>
                    <div className={cx(styles.fieldGroup, styles.autocomplete)}>
                      <label>Seniority</label>
                      <Select
                        isMulti
                        styles={customStyles}
                        value={seniority.value}
                        name="colors"
                        options={jobTitleLeves}
                        onChange={handleOnSelectSeniority}
                        placeholder={"Ex. cxo, owner"}
                      />
                    </div>
                  </div>
                  <div className={styles.filterBox}>
                    <div className={cx(styles.fieldGroup, styles.autocomplete)}>
                      <label>Company Size</label>
                      <Select
                        isMulti
                        styles={customStyles}
                        value={companySize.value}
                        name="colors"
                        options={companySizeOptions}
                        onChange={handleOnSelectCompanySize}
                        placeholder={"Ex. 1-10, 11-50"}
                      />
                    </div>
                  </div>
                  <div className={styles.filterBox}>
                    <div className={cx(styles.fieldGroup, styles.autocomplete)}>
                      <label>Industry</label>
                      <AsyncSelect
                        isMulti
                        styles={customStyles}
                        value={industry.value}
                        cacheOptions
                        loadOptions={handleOnSearchIndustry}
                        defaultOptions
                        onChange={handleOnSelectIndustry}
                        placeholder={"Ex. computer software"}
                        // onInputChange={handleOnSelectCity}
                      />
                    </div>
                  </div>
                  <div className={styles.filterBox}>
                    <label htmlFor="">If nothing's found, get:</label>
                    <label className={styles.checkboxField}>
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          personalEmail.setValue(e.target.checked)
                        }
                        checked={personalEmail.value}
                      />{" "}
                      Personal email
                    </label>
                    {personalEmail.value === false && (
                      <div className={styles.warning}>
                        This will increase your search results. Recommended if
                        you’re targeting small businesses
                      </div>
                    )}
                  </div>
                  <div className={styles.filterBox}>
                    <label htmlFor="">Only find prospects that include: </label>
                    <div className={styles.checkbox}>
                      <label>
                        <input
                          type="checkbox"
                          checked={includePhoneNumber.value}
                          onChange={(e) => {
                            includePhoneNumber.setValue(e.target.checked);
                            if (e.target.checked) {
                              type.setValue(2);
                            }
                          }}
                        />{" "}
                        Phone number{" "}
                        <span className={styles.premium}>premium</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className={styles.footer}>
                  <button
                    onClick={() => searchCount()}
                    className={cx(styles.primary, styles.btn)}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
            <div className={styles.resultsContainer}>
              {step === 1 && (
                <div>
                  <h2>Previous Searches</h2>
                  <SearchResultsComponent
                    user={value.user._id}
                    setFilters={setFilters}
                  />
                </div>
              )}
              {step === 2 && (
                <div className={styles.results}>
                  <div className={styles.header}>
                    Results
                    <div className={styles.numbers}>
                      Showing 10 of{" "}
                      <NumberFormat
                        value={limit - count}
                        displayType={"text"}
                        thousandSeparator={true}
                      />{" "}
                      leads
                    </div>
                  </div>
                  <div>
                    {preview.map((lead, i) => {
                      return (
                        <div className={styles.result} key={`lead-${i}`}>
                          <div className={styles.image}>
                            {(lead.gender === "male" || !lead.gender) && (
                              <img src="/man.png" alt="man" />
                            )}
                            {lead.gender === "female" && (
                              <img src="/woman.png" alt="woman" />
                            )}
                          </div>
                          <div className={styles.details}>
                            <div className={styles.name}>
                              {lead.first_name} {lead.last_name}{" "}
                              <a
                                href={`https://${lead.linkedin_url}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="linkedin"
                              >
                                <i className="fab fa-linkedin-in"></i>
                              </a>{" "}
                            </div>
                            <div className={styles.jobTitle}>
                              <span className={styles.title}>
                                {lead.job_title}
                              </span>{" "}
                              at{" "}
                              <span className={styles.company}>
                                {lead.job_company_name}
                              </span>
                            </div>
                            <div className={styles.email}>
                              Email: <div>********</div>
                            </div>
                            <div className={styles.email}>
                              Phone: <div>********</div>
                            </div>
                            <div className={styles.location}>
                              {lead.location_region &&
                                `${lead.location_region},`}{" "}
                              {lead.location_country}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className={styles.footer}>
                    <button
                      onClick={() => setStep(3)}
                      className={cx(styles.primary, styles.btn)}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              )}

              {step === 3 && (
                <div className={styles.startSearch}>
                  <div className={styles.header}>
                    Start Searching
                    <div className={styles.numbers}>
                      Showing 10 of{" "}
                      <NumberFormat
                        value={limit - count}
                        displayType={"text"}
                        thousandSeparator={true}
                      />{" "}
                      leads
                    </div>
                  </div>
                  <div className={styles.search}>
                    <div>
                      <label htmlFor="" className={styles.labelsInput}>
                        Search Title
                        <span>required</span>
                      </label>
                      <input
                        type="text"
                        onChange={name.onChange}
                        value={name.value}
                      />
                    </div>

                    <div className={styles.leads}>
                      <label htmlFor="" className={styles.labelsInput}>
                        Search Rate
                        <span>required</span>
                      </label>
                      <div className={styles.textDescription}>
                        You have <b>{value.user.credit}</b> credits, how much
                        leads do you want from this search ?{" "}
                      </div>
                      {/* <input
                    type="text"
                    value={qtyLeads.value}
                    onChange={qtyLeads.onChange}
                    placeholder="ej: 100"
                  /> */}
                      <NumberFormat
                        value={qtyLeads.value}
                        placeholder="ej: 100"
                        thousandSeparator={true}
                        className={styles.inputWant}
                        onValueChange={(values) => {
                          const { value } = values;
                          qtyLeads.setValue(value);
                        }}
                      />
                      {qtyLeads.value > value.user.credit && (
                        <div className={styles.error}>
                          You exceeded the credit limit
                        </div>
                      )}
                    </div>
                    <div className={styles.add_campaign}>
                      <label htmlFor="" className={styles.labelsInput}>
                          Add to Campaign
                          <span>not required</span>
                        </label>
                      <select value={selectedCampaign} onChange={handleChange}>
                        <option value="">Select</option>
                        {campaignAI &&
                          campaignAI.map((c) => {
                            return (
                              <option key={c._id} value={c._id}>
                                {c.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>

                    {!includePhoneNumber.value && (
                      <div className={styles.leadsType}>
                        <h4>
                          We can provide you two types of leads,{" "}
                          <b data-tip data-for={`Tooltip-Standard`}>
                            Standard
                          </b>{" "}
                          and{" "}
                          <b data-tip data-for={`Tooltip-Enhanced`}>
                            Premium
                          </b>{" "}
                          what type of leads do you want?
                        </h4>
                        <ReactTooltip
                          id={`Tooltip-Standard`}
                          place="right"
                          type="dark"
                        >
                          <div className="tooltip-box">
                            Data: <br />
                            First Name <br />
                            Last Name <br />
                            Email <br />
                            Social Media <br />
                          </div>
                        </ReactTooltip>
                        <ReactTooltip
                          id={`Tooltip-Enhanced`}
                          place="right"
                          type="dark"
                        >
                          <div className="tooltip-box">
                            Data: <br />
                            First Name <br />
                            Last Name <br />
                            Email <br />
                            Social Media <br />
                            Phone <br />
                            Address
                          </div>
                        </ReactTooltip>
                        <div
                          className={styles.optionContainer}
                          onChange={type.onChange}
                        >
                          <div className={styles.option}>
                            <input type="radio" value="1" name="type" />{" "}
                            Standard
                            <span>1 credit</span>
                          </div>
                          <div className={styles.option}>
                            <input type="radio" value="2" name="type" /> Premium{" "}
                            <span>2 credit</span>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className={styles.costCredits}>
                      <h4>
                        This search have a cost of {qtyLeads.value * type.value}{" "}
                        credits
                      </h4>
                    </div>
                  </div>
                  <div className={styles.footer}>
                    <button
                      className={cx(styles.btn, styles.primary)}
                      onClick={() => startSearch()}
                      disabled={validateStart}
                    >
                      {start_status ? 'Starting...' : 'Start Search'}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </CampaignLayout>
  );
}
