import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { Context } from "../../Context";
import CampaignLayout from "../../layouts/campaign.layout";
import SmallLoaderComponent from "../../components/small-loader/small-loader.component";
import EnrichmentCard from "../../components/enrichment-card/enrichment-card.component";
import styles from "./enrichments.module.scss";
import EnrichmentModal from "../../components/modals/enrichment/enrichment.modal";

export default function EnrichmentsPage() {
  const [user, setUser] = useState(undefined);
  const [enrichments, setEnrichments] = useState(undefined);
  const api = process.env.REACT_APP_API;
  const value = useContext(Context);
  const [openEnrichment, setOpenEnrichment] = useState(false);
  const buyEnrichmentModal = useRef();

  useEffect(() => {
    let user = JSON.parse(localStorage.user);

    axios.get(`${api}user/get/id/${user._id}`).then(({ data }) => {
      setUser(data);
    });

    axios
      .get(`${api}enrichment/all`, {
        params: {
          user: value.user._id,
        },
      })
      .then(({ data }) => {
        setEnrichments(data);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openEnrichmentModal = () => {
    setOpenEnrichment(true);
  };

  return (
    <CampaignLayout user={user}>
      <div className="linkedin-searchs">
        <section className="container">
          <div className={styles.headerLink}>
            <h2>Enrichments</h2>
            <button
              onClick={() => openEnrichmentModal()}
              className={styles.link}
            >
              Enrich my leads
            </button>
          </div>
          {openEnrichment && (
            <EnrichmentModal
              ref={buyEnrichmentModal}
              setOpen={setOpenEnrichment}
            />
          )}

          <br />
          <div className="linkedin-searchs">
            {enrichments ? (
              enrichments.map((enrichment) => {
                return <EnrichmentCard enrichment={enrichment} />;
              })
            ) : (
              <SmallLoaderComponent />
            )}
          </div>
        </section>
      </div>
    </CampaignLayout>
  );
}
