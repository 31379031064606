import React, { useEffect, useState } from "react";
import styles from "./search-results.module.scss";
import axios from "axios";
import moment from "moment";

export default function SearchResultsComponent({ user, setFilters }) {
  const [searchs, setSearchs] = useState([]);
  const api = process.env.REACT_APP_API;

  useEffect(() => {
    axios
      .get(`${api}search/all/`, {
        params: {
          id: user,
        },
      })
      .then(({ data }) => {
        setSearchs(
          data.map((search) => {
            let s = "";
            if (search.filters.country) {
              s += search.filters.country;
            }
            if (search.filters.state) {
              if (s !== "") {
                s += ", ";
              }
              s += search.filters.state;
            }
            if (search.filters.city) {
              if (s !== "") {
                s += ", ";
              }
              s += search.filters.city;
            }
            if (search.filters.title) {
              if (s !== "") {
                s += ", ";
              }
              s += search.filters.title;
            }
            if (search.filters.seniority) {
              if (s !== "") {
                s += ", ";
              }
              s += search.filters.seniority;
            }
            if (search.filters.industry) {
              if (s !== "") {
                s += ", ";
              }
              s += search.filters.industry;
            }
            if (search.filters.textJobTitle) {
              if (s !== "") {
                s += ", ";
              }
              s += search.filters.textJobTitle;
            }
            if (search.filters.companySize) {
              if (s !== "") {
                s += ", ";
              }
              s += search.filters.companySize;
            }

            if (typeof search.filters.personalEmail !== "undefined") {
              if (!search.filters.personalEmail) {
                if (s !== "") {
                  s += ", ";
                }
                s += "no personal emails";
              }
            }

            return {
              ...search,
              filter: s,
              time: moment(search.createdAt).fromNow(),
            };
          })
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filters = (s) => {
    setFilters(
      s.filters.country,
      s.filters.state,
      s.filters.city,
      s.filters.title,
      s.filters.seniority,
      s.filters.industry,
      s.filters.textJobTitle,
      s.filters.personalEmail,
      s.filters.companySize,
    );
  };

  return (
    <>
      {searchs.length === 0 ? (
        <div>
          <div>No Searchs</div>
        </div>
      ) : (
        <div>
          {searchs.map((s) => {
            return (
              <div
                className={styles.result}
                key={s._id}
                onClick={() => filters(s)}
              >
                <div className={styles.icon}>
                  <i className="far fa-search"></i>
                </div>
                <div className={styles.data}>
                  <div className={styles.search}>{s.filter}</div>
                  <div className={styles.time}>{s.time}</div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}
