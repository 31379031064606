import React, { useCallback, useEffect, useState } from "react";
import styles from "./register.module.scss";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "@reach/router";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import axios from "axios";
import toast from "react-hot-toast";
import { useIntercom } from "react-use-intercom";
import { Context } from "../../../Context";

export default function Register() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const api = process.env.REACT_APP_API;
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const onSubmit = async (data) => {
    const recaptchaToken = await executeRecaptcha("signup");
    let body = {
      ...data,
      responseCaptcha: recaptchaToken,
    };

    const promise = axios.post(`${api}auth/register`, body);
    toast.promise(promise, {
      loading: "Signing up...",
      success: () => {
        navigate("/validate", { replace: true });
      },
      error: (error) => {
        return `${error.response.data.message}`;
      },
    });
  };

  return (
    <div className={styles.auth}>
      {/* <GoogleReCaptcha refreshReCaptcha={false} onVerify={onVerify} /> */}
      <div className={styles.authGrid}>
        <div className={styles.formColumn}>
          <img src="/logo.svg" alt="logo" />
          <div className={styles.formContainer}>
            <h1>Create your account</h1>
            <h2>Create your account and get 10 free credits</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={styles.formGroup}>
                <input
                  placeholder="Email"
                  {...register("email", { required: true })}
                />
              </div>

              <div className={styles.formGrid}>
                <div className={styles.formGroup}>
                  <input
                    placeholder="First Name"
                    {...register("first_name", { required: true })}
                  />
                </div>
                <div className={styles.formGroup}>
                  <input placeholder="Last Name" {...register("last_name")} />
                </div>
                <div className={styles.formGroup}>
                  <input
                    placeholder="Password"
                    type="password"
                    {...register("password", { required: true })}
                  />
                </div>
                <div className={styles.formGroup}>
                  <input
                    placeholder="Repeat Password"
                    type="password"
                    {...register("repeat_password", { required: true })}
                  />
                </div>
              </div>

              <input
                type="submit"
                className={styles.button}
                value={"Get Started"}
              />
            </form>
            <p>
              Already have an account? <Link to="/login">Sign in</Link>
            </p>
          </div>
        </div>
        <div className={styles.imageColumn}>
          {/* <img src="/auth-01.jpeg" alt="" /> */}
        </div>
      </div>
    </div>
  );
}
