import React, { useContext, useState } from "react";
import { useInputValue } from "../../hooks/useInputValue";
import axios from "axios";
import { Context } from "../../Context";
import "./RecoverPassword.scss";
import { Link, useNavigate } from "@reach/router";

export default function RecoverPassword() {
  const email = useInputValue("");
  const password = useInputValue("");
  const [error, setError] = useState("");
  const { activateAuth } = useContext(Context);
  const navigate = useNavigate();

  const resetPassword = async () => {
    const api = process.env.REACT_APP_API;
    let body = {
      email: email.value,
    };
    // activateAuth
    axios.post(`${api}auth/recover-password`, body).then(({ data }) => {
      if (data.error) {
        setError(data.error);
      } else {
        console.log(data);
        alert("Instructions sent successfully");
      }
    });
  };

  return (
    <div className="account-pages my-5 pt-sm-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6 col-xl-5">
            <img src="/logo.png" alt="logo" />
            <div className="card overflow-hidden">
              <div className="bg-soft-primary">
                <div className="row">
                  <div className="col-7">
                    <div className="text-primary p-4">
                      <h5 className="text-primary"> Reset Password</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body pt-0">
                <div className="p-2">
                  <div
                    className="alert alert-success text-center mb-4"
                    role="alert"
                  >
                    Enter your Email and instructions will be sent to you!
                  </div>
                  <div className="form-horizontal" action="index.html">
                    <div className="form-group">
                      <label htmlFor="useremail">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        value={email.value}
                        onChange={email.onChange}
                        id="useremail"
                        placeholder="Enter email"
                      />
                    </div>

                    <div className="form-group row mb-0">
                      <div className="col-12 text-right">
                        <button
                          className="btn btn-primary w-md waves-effect waves-light"
                          onClick={resetPassword}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5 text-center">
              <p>
                Remember It ?{" "}
                <Link to="/login" className="font-weight-medium text-primary">
                  {" "}
                  Sign In here
                </Link>{" "}
              </p>
              <p>© 2020 Automated Inbox.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
