import React, { useContext, useState } from "react";
import { useInputValue } from "../../hooks/useInputValue";
import axios from "axios";
import { Context } from "../../Context";
import "./Login.scss";
import { Link, useNavigate } from "@reach/router";

export default function ChangePassword({ token, email }) {
  const password = useInputValue("");
  const repassword = useInputValue("");
  const [error, setError] = useState("");
  const { activateAuth } = useContext(Context);
  const navigate = useNavigate();

  const loginAuth = async () => {
    const api = process.env.REACT_APP_API;
    let body = {
      email: email,
      password: password.value,
      token: token
    };
    // activateAuth
    axios.post(`${api}auth/change-password`, body).then(({ data }) => {
      if (data.error) {
        setError(data.error);
      } else {
        alert("Password Updated Successfully")
        navigate("/login", { replace: true });
      }
    });
  };

    return (<div className="account-pages my-5 pt-sm-5">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-8 col-lg-6 col-xl-5">
          <img src="/logo.png" alt="logo" />
          <div className="card overflow-hidden">
            <div className="card-body pt-0">
              <div className="p-2">
                <div className="form-horizontal">
                  <div className="form-group">
                    <label htmlFor="username">New password</label>
                    <input
                      type="text"
                      className="form-control"
                      value={password.value}
                      onChange={password.onChange}
                      id="email"
                      placeholder="Enter email"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="userpassword">Repeat new password</label>
                    <input
                      type="text"
                      className="form-control"
                      value={repassword.value}
                      onChange={repassword.onChange}
                      id="userpassword"
                      placeholder="Enter password"
                    />
                  </div>
                  {error && <div className="error">{error}</div>}

                  <div className="mt-3">
                    <button
                      className="btn btn-primary btn-block waves-effect waves-light"
                      type="submit"
                      disabled={!password.value || !repassword.value || password.value != repassword.value}
                      onClick={loginAuth}
                    >
                      Change Password
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5 text-center">
            <p>© 2020 Automated Inbox.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
)
}
