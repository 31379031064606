import { Link, useNavigate } from "@reach/router";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Context } from "../../Context";
import "./_Nav.scss";
import axios from "axios";
import { Popover, Whisper } from "rsuite";
import ReactTooltip from "react-tooltip";
import BuyCreditModal from "../modals/buy-credits/buy-credits.modal";
import BuyValidationCreditModal from "../modals/buy-validation-credits/buy-validation-credits.modal";
import { useSelector } from "react-redux";
import NumberFormat from "react-number-format";
import style from "./nav.module.scss";
const api = process.env.REACT_APP_API;

export default function Nav({ openInformation, user, fullWidth }) {
  const value = useContext(Context);
  const buyCreditsModal = useRef();
  const buyValidationCreditsModal = useRef();
  const navigate = useNavigate();
  const [openBuy, setOpenBuy] = useState(false);
  const [emailsSent, setEmailsSent] = useState(0);
  const [openValidationBuy, setValidationOpenBuy] = useState(false);
  const userState = useSelector((store) => store.user);

  const logout = () => {
    if (value.user._id === userState._id) {
      value.removeAuth();
    } else {
      value.activateAuth(value.token, userState);
      navigate("/admin/users");
    }
  };

  const updateUser = () => {
    axios.get(`${api}user/get/id/${value.user._id}`).then(({ data }) => {
      value.updateUser(data);
    });
  };

  const getEmailsSent = () => {
    axios
      .get(`${api}user/get/emails-sent/${value.user._id}`)
      .then(({ data }) => {
        setEmailsSent(data);
      });
  };

  useEffect(() => {
    getEmailsSent();
  }, []);

  return user ? (
    <div className="nav light">
      <div className={`${fullWidth ? "container-fluid" : "container"}`}>
        <div className="nav-logo" onClick={() => navigate("/")}>
          <img src="/logo.svg" className="logo" alt="logo" />
        </div>
        <div className="nav-items">
          <div className="item">
            <a
              href="https://chrome.google.com/webstore/detail/automated-inbox-find-veri/ffpfkdfbbeljmiekhilamiabcihkdomn"
              className="chrome-extension"
              target="_blank"
              data-tip
              data-for={`Tooltip-chrome`}
            >
              <i className="fas fa-certificate"></i> Chrome Extension
            </a>
            <ReactTooltip id={`Tooltip-chrome`} place="bottom" type="dark">
              <div className="tooltip-box">
                Click here to Install Chrome Extension
              </div>
            </ReactTooltip>
          </div>

          <div className="item">
            <span
              className="credits"
              title="Buy Credits"
              onClick={() => setOpenBuy(true)}
            >
              {value.user.credit ? value.user.credit : 0}
              <div>Buy lead credits</div>
            </span>
          </div>
          <div className="item get-leads">
            <Link to="/searches/add/">Get Leads</Link>
          </div>
          <a
            className="item"
            target={"_blank"}
            rel="noopener noreferrer"
            href="https://feedback.automatedinbox.com/feature-requests"
            data-tip
            data-for={`Tooltip-request-feature`}
          >
            <i className="fad fa-lightbulb"></i>
          </a>
          <ReactTooltip
            id={`Tooltip-request-feature`}
            place="bottom"
            type="dark"
          >
            <div className="tooltip-box">Request Feature</div>
          </ReactTooltip>
          <div className="item">
            <Whisper
              trigger="click"
              placement={"bottomEnd"}
              speaker={
                <Popover title="" visible className="information-popover">
                  <div className="option" onClick={openInformation}>
                    Welcome to AI 2.0
                  </div>
                  <div className="option">
                    <a
                      href="https://help.automatedinbox.com/en/"
                      target="_blank"
                    >
                      Help Center
                    </a>
                  </div>
                  <div className="option">
                    <a
                      href="https://help.automatedinbox.com/en/collections/3316640-getting-started"
                      target="_blank"
                    >
                      Getting Started
                    </a>
                  </div>
                </Popover>
              }
            >
              <i className="fad fa-question-circle"></i>
            </Whisper>
          </div>
          <div className="item">
            <Whisper
              trigger="click"
              placement={"bottomEnd"}
              speaker={
                <Popover
                  title={`${value.user.first_name} ${value.user.last_name}`}
                  visible
                >
                  {user.role === "admin" && (
                    <div className="option">
                      <Link to="/admin/users">Administrator</Link>
                    </div>
                  )}
                  <div
                    className="option"
                    title="Buy Credits"
                    onClick={() => setOpenBuy(true)}
                  >
                    Credits: <b>{value.user.credit} </b>
                  </div>
                  <div
                    className="option"
                    onClick={() => setValidationOpenBuy(true)}
                  >
                    Validation Credits: <b>{value.user.validation_credit} </b>
                  </div>
                  <div className="option">
                    <Link to="/profile/personal">Profile</Link>
                  </div>
                  <div className="option" onClick={() => logout()}>
                    Logout{" "}
                    {value.user._id !== userState._id &&
                      `As ${value.user.first_name}`}
                  </div>
                  <div className={style.emailsSent}>
                    Emails Sent This Month
                    <b>
                      <NumberFormat
                        value={emailsSent}
                        prefix={""}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </b>
                  </div>
                  <div className="option-beta">Automated Inbox: 2.5.0</div>
                </Popover>
              }
            >
              <i className="fad fa-user"></i>
            </Whisper>
          </div>
        </div>
      </div>
      {openBuy && (
        <BuyCreditModal
          ref={buyCreditsModal}
          setOpen={setOpenBuy}
          updateUser={updateUser}
        />
      )}
      {openValidationBuy && (
        <BuyValidationCreditModal
          ref={buyValidationCreditsModal}
          setOpen={setValidationOpenBuy}
          updateUser={updateUser}
        />
      )}
    </div>
  ) : (
    <div />
  );
}
