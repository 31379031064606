import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Link } from "@reach/router";
import { Context } from "../../Context";
import moment from "moment";
import CampaignLayout from "../../layouts/campaign.layout";
import DataTable from "react-data-table-component";
import SmallLoaderComponent from "../../components/small-loader/small-loader.component";
import Loading from "../../components/loading/Loading";
import AutomatedSearchCard from "../../components/automated-search-card/automated-search-card.component";
import styles from "./automated-inbox-searchs.module.scss";

export default function AutomatedInboxSearchsPage() {
  const [user, setUser] = useState(undefined);
  const [linkedinSearchs, setLinkedinSearchs] = useState(undefined);
  const api = process.env.REACT_APP_API;
  const value = useContext(Context);

  useEffect(() => {
    let user = JSON.parse(localStorage.user);

    axios.get(`${api}user/get/id/${user._id}`).then(({ data }) => {
      setUser(data);
    });

    axios
      .get(`${api}search/all`, {
        params: {
          id: value.user._id,
        },
      })
      .then(({ data }) => {
        setLinkedinSearchs(data);
        // setScraped(count);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CampaignLayout user={user}>
      <div className="linkedin-searchs">
        <section className="container">
          <div className={styles.headerLink}>
            <h2>Automated Inbox Searches</h2>
            <Link to="/searches/add/" className={styles.link}>
              <img
                src="https://automatedinbox.s3.ap-southeast-1.amazonaws.com/ai-icon-e1672106929333.png"
                alt="icon"
              />{" "}
              Get Leads
            </Link>
          </div>
          <br />

          <div className="linkedin-searchs">
            {linkedinSearchs ? (
              linkedinSearchs.map((sales) => {
                return <AutomatedSearchCard sales={sales} key={sales._id} />;
              })
            ) : (
              <SmallLoaderComponent />
            )}
          </div>
        </section>
      </div>
    </CampaignLayout>
  );
}
