import { useNavigate } from "@reach/router";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Context } from "../../../Context";
import styles from "./sales-nav.module.scss";
import axios from "axios";
import Truncate from "react-truncate";
import cx from "classnames";

export default function SalesNavWidget() {
  const value = useContext(Context);
  const navigate = useNavigate();
  const [searchs, setSearchs] = useState([]);
  const api = process.env.REACT_APP_API;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${api}widget/sales-navigator`, {
        params: {
          user: value.user._id,
        },
      })
      .then(({ data }) => {
        setSearchs(data);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <div className={styles.widget}>
      <div className={styles.title}>Sales Navigator Searches</div>
      <div className={styles.searchs}>
        <button
          className={styles.linked}
          onClick={() =>
            window.open(
              "https://www.linkedin.com/sales/search/people",
              "_blank"
            )
          }
        >
          <label htmlFor="">Get leads</label>
          Using Sales Navigator <i className="fab fa-linkedin-in"></i>
        </button>
        {!loading &&
          searchs.map((search) => {
            return (
              <div
                className={styles.search}
                onClick={() =>
                  navigate(`/sales-navigator-searches/${search._id}`)
                }
                key={search._id}
              >
                <div className={styles.rate}>{search.rate}</div>
                <div className={styles.details}>
                  <div className={styles.name}>
                    <Truncate width={200} ellipsis={"..."}>
                      {search.name}
                    </Truncate>
                  </div>
                  <div className={cx(styles.status, styles[search.status])}>
                    {search.status}
                  </div>
                </div>
              </div>
            );
          })}
        {!loading && searchs.length === 0 && (
          <div className={styles.nothing}>You don't have any search yet</div>
        )}
      </div>
    </div>
  );
}
