import { Link } from "@reach/router";
import React, { useCallback, useContext, useEffect, useState } from "react";
import styles from "./validated.module.scss";
import axios from "axios";
import toast from "react-hot-toast";

export default function Validated({ id }) {
  const api = process.env.REACT_APP_API;

  useEffect(() => {
    const promise = axios
      .post(`${api}auth/validate`, { id })
      .then(({ data }) => {
        if (data.error) {
          toast.error(data.error);
        } else {
        }
      });

    toast.promise(promise, {
      loading: "Verifying...",
      success: "Email verified!",
      error: "Uh oh, there was an error!",
    });
  }, []);

  return (
    <div className={styles.auth}>
      {/* <GoogleReCaptcha refreshReCaptcha={false} onVerify={onVerify} /> */}
      <div className={styles.authGrid}>
        <div className={styles.formColumn}>
          <img src="/logo.svg" alt="logo" />
          <div className={styles.formContainer}>
            <h1>Email Verified</h1>
            <p>
              Ready ? <Link to="/login">Sign in</Link>
            </p>
          </div>
        </div>
        <div className={styles.imageColumn}>
          {/* <img src="/auth-01.jpeg" alt="" /> */}
        </div>
      </div>
    </div>
  );
}
