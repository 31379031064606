import React, { useContext, useEffect, useState } from "react";
import "./linkedin-searchs.scss";
import axios from "axios";
import CampaignLayout from "../../layouts/campaign.layout";
import { Context } from "../../Context";
import SmallLoaderComponent from "../../components/small-loader/small-loader.component";
import SalesNavigatorCard from "../../components/sales-navigator-card/sales-navigator-card.component";
import styles from "./linkedin-searchs.module.scss";
import { Link } from "@reach/router";

export default function LinkedinSearchsPage() {
  const [user, setUser] = useState(undefined);
  const [linkedinSearchs, setLinkedinSearchs] = useState(undefined);
  const api = process.env.REACT_APP_API;
  const value = useContext(Context);

  useEffect(() => {
    let user = JSON.parse(localStorage.user);

    axios.get(`${api}user/get/id/${user._id}`).then(({ data }) => {
      setUser(data);
    });

    axios
      .get(`${api}linkedin-search/all`, {
        params: {
          user: value.user._id,
        },
      })
      .then(({ data }) => {
        setLinkedinSearchs(data);
      });
  }, []);

  const loadMore = () => {
    console.log(linkedinSearchs.length);
    axios
      .get(`${api}linkedin-search/all`, {
        params: {
          user: value.user._id,
          skip: linkedinSearchs.length,
        },
      })
      .then(({ data }) => {
        let newArray = [...linkedinSearchs, ...data];
        setLinkedinSearchs(newArray);
      });
  };

  return (
    <CampaignLayout user={user}>
      <div className="linkedin-searchs">
        <section className="container">
          <div className={styles.headerLink}>
            <h2>Sales Navigator Searches</h2>
            <a
              href="https://www.linkedin.com/sales/search/people"
              target={"_blank"}
              rel="noopener noreferrer"
              className={styles.link}
            >
              <i
                style={{ color: "#004e89", fontSize: 14 }}
                className="fab fa-linkedin-in"
                data-tip
                data-for={`Tooltip-linkedin`}
              ></i>{" "}
              Get Leads
            </a>
          </div>
          <br />

          <div className="linkedin-searchs">
            {linkedinSearchs ? (
              linkedinSearchs.map((sales) => {
                return <SalesNavigatorCard sales={sales} key={sales._id} />;
              })
            ) : (
              <SmallLoaderComponent />
            )}
          </div>
          <button className="loadmore-btn" onClick={() => loadMore()}>
            Load More
          </button>
        </section>
      </div>
    </CampaignLayout>
  );
}
